<template>
    <v-main>
        <app-menu/>
        <div class="text-center">
            <v-dialog v-model="isLoad" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                Cargando ...
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
            </v-dialog>
        </div>
        <v-toolbar>
            <v-tabs
            v-model="tab"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
            >
                <v-tabs v-model="tab" background-color="error"  grow dark>
                    <v-tab>Modo Mantenimiento</v-tab>
                </v-tabs>
            </v-tabs>
        </v-toolbar>
        <v-card min-height="100%" >
            <v-col style="text-align:center;" v-if="tab==0">
                <v-row >
                    <v-col>
                        <v-card max-width="800" class="text-center" style="margin:0 auto;">
                            <v-card-title >
                                BIENVENID@ AL MODO MANTENIMIENTO
                            </v-card-title>
                            <v-card-text>
                                <v-divider color="error"></v-divider>
                                <div class="container">
                                    <v-row class="mx-auto">
                                        <v-col md="8">
                                            <div class="text-h6" style="">
                                                Activar modo mantenimiento
                                            </div>
                                        </v-col>
                                        <v-col md="4">
                                            <v-switch
                                            v-model="mantenimiento.activar"
                                            color="error"
                                            :label="mantenimiento.activar == false ? 'Activar': 'Desactivar'"
                                            @click="activar_inactivar()"
                                            ></v-switch>
                                        </v-col>
                                        <v-row justify="center">
                                            <!-- <v-date-picker></v-date-picker> -->
                                        </v-row>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-main>
</template>
<script>
import AppMenu from '../../components/admin/MenuAdmin.vue';
import axios from 'axios';
import config from '../../json/config.json';

export default {
    data(){
        return{
            tab: "",
            mantenimiento:{activar:""},
            isLoad: false,
            // mantenimiento:false,
        }
    },
    components: {
        "app-menu": AppMenu
    },
    methods: {
        async activar(){
            axios.post(config.apiAmoresens + '/mantenimiento/activarmantenimiento',this.mantenimiento,
            {'headers': { 'token': this.$cookie.get('token') }})
            .then(res=>{return res.data;})
            .catch(err=>{return err})
        },
        async activar_inactivar(){
            await axios.put(config.apiAmoresens + '/mantenimiento/activar_inactivar_mantenimiento',this.mantenimiento,
            {'headers': { 'token': this.$cookie.get('token') }})
            .then(res=>{return res.data;})
            .catch(err=>{return err})
        },
    },
    async created(){
        this.isLoad = true;
        this.user = await axios.get(config.apiAmoresens + "/user/userByTokenAdmin", 
            {'headers': { 'token': this.$cookie.get('token') }})
            .then(res=>{return res.data;})
            .catch(err=>{return err;});     
            if (this.user.status == "success") {
            this.user = this.user.user; 
            this.isLogged = true;
            }else if(this.user.status == "unauthorized"){ 
            this.$router.push('/shop/admin'+config.matchAdmin+'/login');
            this.isLogged = false;
            } 
            
        
        await axios.get(config.apiAmoresens + '/mantenimiento/mantenimiento')
            .then(res=>{
                this.mantenimiento.activar = res.data.data[0].activo;
            }).catch(err=>{return err})
        this.isLoad = false;
    }
}
</script>